import { SignedIn, SignedOut } from '@clerk/clerk-react'
import React from 'react'
import Layout from '../components/molecules/layout'
import SignUpModal from '../components/sign-up-modal'
import { SiAlfred, SiZapier, SiGooglechrome } from 'react-icons/si'
import { RiMessengerFill, RiTelegramFill } from 'react-icons/ri'
import { AiFillMessage } from 'react-icons/ai'
import { MdEmail } from 'react-icons/md'
import { Link } from 'gatsby'

function RootIndex() {
  return (
    <Layout>
      <section className="section main-landing">
        <div className="container">
          <div className="columns">
            <div className="column is-7">
              <h1>phonetonote</h1>
              <h2>
                <strong>quick</strong>ly <strong>capture</strong> your thoughts,
                as a service
              </h2>
            </div>
            <div className="column is-5">
              <div className="live-unbothered">
                <h1>live unbothered</h1>
              </div>
            </div>
          </div>
          <div className="columns tall-row">
            <div className="column is-full">
              <SignedOut>
                <SignUpModal buttonClass="get-started-button-home" />
              </SignedOut>
              <SignedIn>
                <a
                  href="https://dashboard.phonetonote.com/"
                  className="button is-primary go-to-dash-button"
                >
                  go to dashboard
                </a>
              </SignedIn>
            </div>
          </div>

          <div className="columns">
            <div className="column is-6-fullhd is-6-widescreen is-8-tablet is-12-mobile">
              <div className="platform-blocks">
                <div className="platform-block">
                  <div className="platform-block-inner">
                    <div>sms</div>
                    <AiFillMessage />
                  </div>
                </div>
                <div className="platform-block">
                  <div className="platform-block-inner">
                    <div>email</div>
                    <MdEmail />
                  </div>
                </div>
                <div className="platform-block">
                  <div className="platform-block-inner">
                    <div>chrome</div>
                    <SiGooglechrome />
                  </div>
                </div>

                <div className="platform-block">
                  <div className="platform-block-inner">
                    <div>telegram</div>
                    <RiTelegramFill />
                  </div>
                </div>
                <div className="platform-block">
                  <div className="platform-block-inner">
                    <div>alfred</div>
                    <SiAlfred />
                  </div>
                </div>
                <div className="platform-block">
                  <div className="platform-block-inner">
                    <div>zapier</div>
                    <SiZapier />
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-3-fullhd is-4-widescreen is-offset-1-widescreen is-offset-2-fullhd is-6-desktop">
              <div
                className="platform-blocks platform-blocks-clients"
                style={{ margin: '20px 0' }}
              >
                <div className="platform-block">logseq</div>
                <div className="platform-block">roam</div>
                <div className="platform-block">
                  <div className="platform-block-inner">
                    <Link to="changelog/phone-to-kinopio" className="new-badge">
                      new, learn more
                    </Link>
                    <div>kinopio</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default RootIndex
